<template>
  <div class="sign_custom_container">
    <div class="sign_custom_back"></div>
    <div class="sign_custom_main">
      <div class="sign_custom_title">百展讲堂</div>
      <div class="sign_custom_title_second">会议报名及签到中心</div>
      <div class="sign_custom_content global_shadow">
        <img class="sign_doctor"  :src="require('@/assets/images/sign/doctor.png')" @load="initScroll" alt="">
        <div class="sign_icon">
          <img  :src="require('@/assets/images/login/icon.png')" @load="initScroll" alt="">
        </div>
        <div class="sign_form global_container_center">
          <div class="sign_custom_block">
            <div class="sign_custom_block_key">姓名</div>
            <div class="sign_custom_block_value">
              <el-input placeholder="请输入姓名" v-model="data.name"></el-input>
            </div>
          </div>
        </div>
        <div class="sign_custom_btn_group global_container_center">
          <el-button class="custom-button" type="primary" @click.native="doSign" round>确认</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sign",
  data() {
    return {
      data: {
        name: "",
      },
    };
  },
  created() {
    document.getElementsByTagName("title")[0].innerHTML = "签到";
  },
  methods: {
    formatData() {
      if (!this.data.name) {
        this.$tips.error({ text: "请输入名字" });
        return false;
      }
      return true;
    },
    doSign() {
      if (!this.formatData() || this.loading) return;
      this.loading = true;
      this.$axios.post(this.$urls.client.sign, this.data).then((res) => {
        setTimeout(() => { this.loading = false;  }, 1000);
        this.$router.push({
          path:`/live`,
          query:{from:'sign',...this.$route.query},
        });
      }).catch(err=>{
        setTimeout(() => { this.loading = false;  }, 1000);
      });
    },
  },
};
</script>

<style>
.sign_custom_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  background: url("../../../assets/images/sign/background.png");
  background-size:100% 100%;
}
.sign_custom_content{
  width:88vw;
  height:88vw;
  background: #fff;;
  display: flex;
  flex-direction: column;
  padding:8vw 4vw;
  border-radius: 2vw;;
  margin-top:30vw;
  position: relative;
}
.sign_doctor{
  width:40vw;
  height:auto;
  position: absolute;
  right:0;
  top:-24vw;
}
.sign_custom_back {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #f5f5f5;
  opacity: 0.2;
}
.sign_custom_main {
  position: relative;
  z-index: 2;
  /* width: 70%; */
}
.sign_form{
  flex:1 0 0
}
.sign_custom_block{
  width:100%;
}
.sign_custom_block input {
  border: none;
  font-size: 4vw;
  padding-left: 0;
  padding-right: 0;
}
.sign_custom_block_key {
  /* font-size:6vw; */
  font-weight: 700;
  flex: 20vw 0 0;
  text-align: center;
}
.sign_custom_block_value {
  flex: 1 0 0;
  border-bottom: 1px solid #f1f1f1;
}
.sign_custom_btn_group {
  width: 100%;
  margin-top: 6vw;
}
.sign_custom_btn_group button {
  width: 30vw;
}
.sign_custom_btn_group .el-input__icon {
  width: 6vw !important;
  line-height: 20vw;
}
.sign_custom_block {
  height: 12vw;
  background: #fff;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
}
.sign_custom_block:first-child {
  /* border-bottom:none;
    border-top-left-radius: 1vw;
    border-top-right-radius: 1vw; */
}

.sign_custom_block:last-child {
  /* border-bottom: 1px solid #f1f1f1; */
  border-bottom-left-radius: 1vw;
  border-bottom-right-radius: 1vw;
}
.sign_custom_title {
  font-size: 8vw;
  font-weight: 700;
  height: 6vw;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  color:#fff;
}
.sign_custom_title_second {
  font-size: 5vw;
  height: 18vw;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color:#fff;
}
.sign_custom_container .el-input-group__append {
  border: none !important;
  background: none !important;
}
.sign_icon{
  padding-left:10vw;
}
.sign_icon img{
  width:12vw;
  height:auto;
}
</style>
